@font-face {
  font-family: bahn;
  src: url(./assets/fonts/bahnschrift.ttf);
}

html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: bahn;
  background : -moz-linear-gradient(49.84% 11.92% -89.76deg,rgba(0, 161, 228, 1) 0%,rgba(0, 147, 212, 1) 19.47%,rgba(0, 109, 171, 1) 57.37%,rgba(0, 61, 118, 1) 100%);
  background : -webkit-linear-gradient(-89.76deg, rgba(0, 161, 228, 1) 0%, rgba(0, 147, 212, 1) 19.47%, rgba(0, 109, 171, 1) 57.37%, rgba(0, 61, 118, 1) 100%);
  background : -webkit-gradient(linear,49.84% 11.92% ,50.18% 92.9% ,color-stop(0,rgba(0, 161, 228, 1) ),color-stop(0.1947,rgba(0, 147, 212, 1) ),color-stop(0.5737,rgba(0, 109, 171, 1) ),color-stop(1,rgba(0, 61, 118, 1) ));
  background : -o-linear-gradient(-89.76deg, rgba(0, 161, 228, 1) 0%, rgba(0, 147, 212, 1) 19.47%, rgba(0, 109, 171, 1) 57.37%, rgba(0, 61, 118, 1) 100%);
  background : -ms-linear-gradient(-89.76deg, rgba(0, 161, 228, 1) 0%, rgba(0, 147, 212, 1) 19.47%, rgba(0, 109, 171, 1) 57.37%, rgba(0, 61, 118, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#00A1E4', endColorstr='#003D76' ,GradientType=0)";
  background : linear-gradient(179.76deg, rgba(0, 161, 228, 1) 0%, rgba(0, 147, 212, 1) 19.47%, rgba(0, 109, 171, 1) 57.37%, rgba(0, 61, 118, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00A1E4',endColorstr='#003D76' , GradientType=1);
  height: 100%;
}

*,
*:before,
*:after {
  font-family: bahn;
  box-sizing: inherit;
}

body {
  user-select: none;
  touch-action: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; 
  font-family: bahn; 
}


body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: bahn;
  color: #fff200;
}


@media screen and (orientation:portrait) {
  .App {
    visibility: hidden;
  }

  .rotator {
    position: absolute;
    top: 0;
    left: 0;
    visibility: visible;
    display: block;
    width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    background-position: center center;
    background-image: url('assets/images/rotate.gif');
    background-size: 50%;
    filter: invert(100);
  }
}

@media screen and (orientation:landscape) {
  .App {
    visibility: visible;
  }
}



.App-logo {
  height: 65vmin;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation-name: get-in;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.App-izvestuvanje {
  height: 80vmin;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-name: get-in;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes get-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.Next-button--main {
  position: absolute;
  right: 3%;
  bottom: 3%;
  filter: invert(100%);
}
.Done-button--main {
  position: absolute;
  right: 3%;
  bottom: 3%;
}
.Prev-button--main {
  position: absolute;
  left: 3%;
  bottom: 3%;
  filter: invert(100%);
  transform: rotate(180deg);
}

.arrow-mid {
  display: block;
  max-width: 60px;
  margin: 0 auto;
}

.end-hero {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44vh;
  animation-name: get-in;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.row h1 {
  font-size: 1rem;
}

.col-right * {
  text-align: center;
}

.main_container {
  padding-top: 6vh;
  padding-bottom: 6vh;
  padding-left: 2vh;
  padding-right: 2vh;
}

.row div {
  /* margin: .5rem; */
}

.flex-up {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  justify-content: space-around;
}

.element {
  display: block;
  width: 70%;
  margin: 0 auto;
}

.spacer {
  margin-left: -3rem;
}

.spacer-2 {
  padding-right: 2rem;
}
.spacer-3 {
  padding-right :3rem;
}









#row2-2 > .col-left > img, #row3-2 > .col-left > img {

  max-width: 60% !important;
}

.row > div {
  opacity: 0;
}
.row > .col-mid {
  opacity: 1;
}
.row .col-left {
  opacity: 1;
}

.show-up > .col-right {
  opacity: 1;
  transition: opacity 1s;
}


#row3-1 img {
  width: 98%;
}
#row3-1 h1 {
  margin-left: 1%;
}

#row1-1 > div.col-right > img, #row1-2 > div.col-right > img, #row2-1 > div.col-left > img {
  width: 80%;
}
#row2-2 > div.col-left {
  /* flex-basis: 50%; */
  margin-left: 5%;
}
#row2-2 > div.col-left img{
  /* max-height: 140px; */
  object-fit: contain;
}
